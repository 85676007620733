import {Model, ModelInstance} from './Common';
import {Role} from './Role';
import {PreferenceInstance} from './Preference';
import {Container} from '../../bcm/model';

export class UserInstance extends ModelInstance {
  company = '';
  currency = null;
  organization = '';
  preference = null;
  roles = [];
  rolesIds = [];
  indicesIds = [];
  containers = [];
  containersIds = [];
  username = '';
  lastConnexion = '';

  constructor(user) {
    super(user);
    this.company = user.company || '';
    this.organization = user.organization || '';
    this.preference = user.preference && new PreferenceInstance(user.preference);
    this.roles = user.roles ? new Role().getMany(user.roles) : [];
    this.rolesIds = this.roles.map(r => r.id);
    this.indicesIds = user.index ? user.index.map(r => r.indexId) : [];
    this.username = user.username || '';
    this.lastConnexionDate = user.lastConnexionDate
      ? `${new Date(user.lastConnexionDate).toLocaleDateString()} ${new Date(
          user.lastConnexionDate
        ).toLocaleTimeString()}`
      : '';
    this.tooManyPasswordAttempt = user.tooManyPasswordAttempt || false;

    this.containers = user.containers ? new Container().getMany(user.containers) : [];
    this.containersIds = this.containers.map(c => c.id);

    if (this.company === '') this.company = null;
    if (this.organization === '') this.organization = null;
  }
}

export class User extends Model {
  getMany = users => users.map(user => new UserInstance(user));
  getOne = user => new UserInstance(user);
}
